@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.button {
  @include reset-button;

  display: grid;
  grid-template: "cell";

  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--icon-color);
  padding: $space-xxxs;
  box-sizing: content-box;

  > * {
    grid-area: cell;
    place-self: center;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

// When using the progress indicator, the play/pause icon
// needs to be reduced in size in order to fit properly
// within the progress ring.
:is(body) .reduceSize {
  transform: scale(0.5);
}
