@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

// Used as a wrapper for the icon elements.
.icons {
  display: flex;
  position: absolute;
  gap: $space-xxxs;
  bottom: 0;
  right: 0;
  padding: $space-sm;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
