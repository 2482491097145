@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

// When an icon has multiple elements (eg. when the
// Progress indicator is used, both the indicator and
// the play/pause icon are bundled together), the
// .iconContainer class serves as a wrapper element.
// In those cases, an alternate icon class would
// typically be used for the icon element (eg.
// .iconReducedSize).
body .icon,
body .iconContainer {
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--icon-color);
  padding: $space-xxxs;
  box-sizing: content-box;

  svg {
    width: 100%;
    height: 100%;
  }
}
