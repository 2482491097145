@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 100vh;

  @include lg-min {
    padding-bottom: 32px;
  }
}

.imageWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.textWrapper {
  position: absolute;
  z-index: 1;
  padding: $space-lg;
  background: rgba(24, 24, 24, 0.8);
  backdrop-filter: blur(3px);
  color: $color-white;

  @include sm-max {
    bottom: 0;
  }

  @include md-min {
    top: 30%;
  }

  @include lg-min {
    top: 30%;
  }

  @include xl-min {
    top: 45%;
    margin: 0 $space-lg;
  }
}

.image {
  position: relative;
  top: -100px;
  width: 100%;

  @include sm-max {
    min-width: 500px;
  }

  @include lg-max {
    min-width: 600px;
  }
}

.title {
  font-size: $font-size-lg;
  font-weight: $font-weight-light;
  line-height: 1;
  padding-bottom: $space-sm;
  position: relative;
  width: 100%;

  p {
    margin: 0;
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    left: -50%;
    right: -50%;
    background-color: $color-white;
    z-index: 1;
    bottom: 0;
  }

  @include md-min {
    font-size: $font-size-xl;
    font-weight: $font-weight-base;
  }

  @include xl-min {
    font-size: #{$font-size-xl + 2px};
  }
}

.text {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  line-height: 1.222;
  letter-spacing: $letter-spacing-small-text;

  p {
    margin: $space-sm 0;
  }

  @include lg-min {
    min-height: 130px;
    font-size: $font-size-md;
  }
}

.link {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  letter-spacing: $letter-spacing-small-text;
  margin: 0;
}
