@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.hoverStyle {
  height: 100%;

  img {
    transition: all 0.5s cubic-bezier(0.22, 0.01, 0.01, 0.99);
  }

  &.grow {
    &:hover {
      img {
      transform: scale(1.1);
      }
    }
  }

  &.opacityGrow {
    &:hover {
      img {
      transform: scale(1.1);
      opacity: 0.8;
      }
    }
  }
}
