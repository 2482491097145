@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

// Brand adjustable values
$title-color: $color-black !default;
$title-font-size: $font-size-lg !default;
$title-font-weight: $font-weight-base !default;
$title-font-style: normal !default;
$title-font-family: $heading-font !default;
$title-text-transform: none !default;

.container {
  --width: calc(var(--video-width) * 1px);
  --height: calc(var(--video-height) * 1px);
  --icon-size: 25px;
  --icon-color: #{$color-white};
  position: relative;
  display: flex;
  overflow: hidden;
}
