@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.buttonContainer {
    width: 100%;
    display: flex;
}

.button {
    display: flex;
    flex-direction: column;
    height: 36px;
    margin: $space-xs 0;
}

.left {
    align-items: flex-start;
    text-align: left;
}

.center {
    text-align: center;
    align-items: center;
}

.right {
    text-align: right;
    align-items: flex-end;
}

.marginXSm {
    margin-left: $space-sm;
    margin-right: $space-sm;
}

.marginXMd {
    margin-left: $space-md;
    margin-right: $space-md;
}

.marginXLg {
    margin-left: $space-lg;
    margin-right: $space-lg;
}

.marginXNone {
    margin-left: 0;
    margin-right: 0;
}

.marginYSm {
    margin-top: $space-sm;
    margin-bottom: $space-sm;
}

.marginYMd {
    margin-top: $space-md;
    margin-bottom: $space-md;
}

.marginYLg {
    margin-top: $space-lg;
    margin-bottom: $space-lg;
}

.marginYNone {
    margin-top: 0;
    margin-bottom: 0;
}

.paddingXLg {
    padding-left: $space-lg;
    padding-right: $space-lg;
}

.paddingXXl {
    padding-left: $space-xl;
    padding-right: $space-xl;
}

.paddingXNone {
    padding-left: 0;
    padding-right: 0;
}

.paddingYSm {
    padding-top: $space-sm;
    padding-bottom: $space-sm;
}

.paddingYMd {
    padding-top: $space-md;
    padding-bottom: $space-md;
}

.paddingYLg {
    padding-top: $space-lg;
    padding-bottom: $space-lg;
}

.paddingYXl {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
}

.paddingYNone {
    padding-top: 0;
    padding-bottom: 0;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyAround {
    justify-content: space-around;
}

.justifyEvenly {
    justify-content: space-evenly;
}
